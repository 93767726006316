/* eslint-disable jsx-a11y/media-has-caption */
import { detect } from 'detect-browser';
import platform from 'platform';
import { useCallback, useEffect, useState } from 'react';
import {
  ReactMediaRecorder,
  useReactMediaRecorder,
} from 'react-media-recorder';
import { useSelector } from 'react-redux';

import { selectUser } from '../../features/auth/authSlice';
import {
  useSendMediaMutation,
  useSendWebcamLogMutation,
} from '../../features/exam/certificationApiSlice';
import { sleep } from '../../hooks/sleep';
import { VideoPreview } from '../VideoPreview';

export type CameraProps = {
  type: 'video' | 'photo' | 'screen' | 'none';
  certification_log?: string;
  captureDuration?: number; // in minutes
  captureInterval?: number; // in minutes
};

let recordTimeOut: NodeJS.Timeout;

export function CameraComponent({
  type,
  certification_log,
  captureDuration = 15, // time in seconds
  captureInterval = 2, // time in minutes
}: CameraProps) {
  const user = useSelector(selectUser);
  const browser = detect();
  const [sendMedia, { data: sendMediaResult }] = useSendMediaMutation();
  const [registerWebcamLog] = useSendWebcamLogMutation();

  const captureDurationInMilliseconds = captureDuration * 1000;
  const captureIntervalInMilliseconds = captureInterval * 60 * 1000;

  const [cameraEnabled, setCameraEnabled] = useState(true);

  const uploadMedia = (blob: Blob) => {
    try {
      const extra = {
        session: 'webcam',
        type,
        ...(certification_log && { certification_log }),
        ...(platform.description && { device_info: platform.description }),
        ...(user?._id && { player: user._id }),
      };

      const formData = new FormData();

      formData.append('file', blob, 'video.mp4');
      formData.append('extra', JSON.stringify(extra));

      sendMedia(formData);
    } catch (e) {
      console.error('uploadMedia error', e);
    }
  };

  const { status, startRecording, stopRecording, previewStream } =
    useReactMediaRecorder({
      video: {
        facingMode: 'user',
      },
      audio: true,
      askPermissionOnMount: true,
      stopStreamsOnStop: false,
      onStop(_blobUrl, blob) {
        uploadMedia(blob);
      },
    });

  const captureVideo = useCallback(async () => {
    if (status === 'recording') {
      stopRecording();
    }

    startRecording();

    await sleep(captureDurationInMilliseconds);

    stopRecording();

    if (recordTimeOut) {
      clearTimeout(recordTimeOut);
    }

    recordTimeOut = setTimeout(() => {
      captureVideo();
    }, captureIntervalInMilliseconds);
  }, [
    status,
    captureDurationInMilliseconds,
    captureIntervalInMilliseconds,
    startRecording,
    stopRecording,
  ]);

  useEffect(() => {
    const statusUpdate = window.localStorage.getItem('certifica-updated');

    if (statusUpdate !== 'false')
      window.localStorage.setItem('certifica-updated', 'false');

    if (!sendMediaResult) return;
    if (sendMediaResult.status === 'OK' && sendMediaResult.uploads.length > 0) {
      const upload = sendMediaResult.uploads[0];
      if (upload) {
        const payload = {
          time: new Date(),
          url: upload.url,
        };
        registerWebcamLog(payload);
      }
    }
  }, [sendMediaResult, registerWebcamLog]);

  useEffect(() => {
    const timer = setTimeout(() => {
      captureVideo();
    }, 6000);

    return () => {
      setCameraEnabled(false);
      stopRecording();
      clearTimeout(timer);
      clearTimeout(recordTimeOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (browser && previewStream && cameraEnabled) {
    return (
      <div className="mt-3 mb-0">
        <ReactMediaRecorder
          video
          render={() => {
            return (
              <VideoPreview
                width={150}
                height={115}
                stream={previewStream}
                rounded
              />
            );
          }}
        />
      </div>
    );
  }

  return <p>Carregando...</p>;
}
