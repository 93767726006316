import { validateBr } from 'js-brasil';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { keycloak } from '../../lib/keycloakConfig';
import { sanitizeCpf } from '../../utils/sanitize-cpf';
import { useLoginMutation } from '../auth/authApiSlice';
import { logout, setCredentials } from '../auth/authSlice';

export function SsoCallback() {
  const [login] = useLoginMutation();
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = useCallback(
    async (username: string) => {
      try {
        const isValidCpf = validateBr.cpf(username);

        const parsedUsername = isValidCpf ? sanitizeCpf(username) : username;
        const data = await login({
          username: parsedUsername,
          password: 'fun123',
          apiKey: '645900d1c598e96119aea368',
        }).unwrap();

        if (data?.token) {
          dispatch(setCredentials(data));

          navigate('/my-certifications', {
            state: { from: location },
            replace: true,
          });
        } else {
          console.error('Login error', data);
          keycloak.logout();
          dispatch(logout());
        }
      } catch (error) {
        console.error('Login error', error);
      }
    },
    [login, dispatch, navigate, location],
  );

  useEffect(() => {
    // const updateToken = (refresh = false) => {
    //   if (refresh) {
    //     keycloak.updateToken(70).then(refreshed => {
    //       if (refreshed) {
    //         dispatch(setToken(keycloak.token));
    //       }
    //     });
    //   }
    // };

    // keycloak.onTokenExpired = () => {
    //   updateToken(true);
    // };

    const initKeycloak = async () => {
      try {
        const authenticated = await keycloak.init({
          onLoad: 'login-required',
        });
        if (authenticated) {
          // const userInfo = await keycloak.loadUserInfo();
          // example userInfo: {
          //   sub: 'b243cc53-d768-4fa2-8988-4a92f369f9ed',
          //   email_verified: true,
          //   name: 'Teste Com',
          //   preferred_username: '123456789',
          //   given_name: 'Teste',
          //   locale: 'en',
          //   family_name: 'Com',
          //   email: 'teste@teste.com'
          // }
          // ir buscar dados adicionais do usuário
          handleLogin('123456789');
        } else {
          dispatch(logout());
        }
        dispatch(logout());
      } catch (error) {
        dispatch(logout());
        console.error('Keycloak initialization error', error);
        console.error(error);
      }
    };

    initKeycloak();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{/* Seu componente JSX */}</div>;
}
