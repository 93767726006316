// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer;

const apiKeyFromEnv = process.env.REACT_APP_APIKEY;
const registrationToken = process.env.REACT_APP_REGISTRATION_TOKEN_KEY;

export const getRegistrationToken = (apiKey?: string) => {
  let token = `${apiKeyFromEnv}:${registrationToken}`;

  if (apiKey !== null && apiKey !== undefined) {
    token = `${apiKey}:${registrationToken}`;
  }

  return `Basic ${Buffer.from(token).toString('base64')}`;
};
