import { zodResolver } from '@hookform/resolvers/zod';
import { validateBr } from 'js-brasil';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as z from 'zod';

import { Button } from '../../components/Button';
import { sanitizeCpf } from '../../utils/sanitize-cpf';
import { useRecoveryPasswordCodeMutation } from '../auth/authApiSlice';

const RecoverySchema = z.object({
  code: z.string().min(1, 'Código obrigatório'),
  username: z.string().min(1, 'Usuário obrigatório'),
  password: z.string().min(1, 'Nova senha obrigatória'),
});

type RecoveryForm = z.infer<typeof RecoverySchema>;

export function RecoveryPassword() {
  const location = useLocation() as { state: { apiKey: string } };
  const { apiKey = '' } = location.state || {};
  const navigate = useNavigate();
  const [recoveryPasswordCode] = useRecoveryPasswordCodeMutation();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm<RecoveryForm>({
    mode: 'all',
    resolver: zodResolver(RecoverySchema),
  });

  async function handleRecoveryPassword({
    username,
    code,
    password,
  }: RecoveryForm) {
    try {
      const isValidCpf = validateBr.cpf(username);

      const parsedUsername = isValidCpf ? sanitizeCpf(username) : username;

      const data = await recoveryPasswordCode({
        username: parsedUsername,
        code,
        password,
        apiKey,
      }).unwrap();

      if (data && data.type === 'OK') {
        toast.success('Senha alterada com sucesso', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        navigate('/login', {
          state: { from: location },
          replace: true,
        });
      }

      if (data && data.type === 'Unauthorized') {
        toast.error('Código inválido ou já utilizado', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      toast.error('Código inválido ou já utilizado', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  return (
    <div className="mt-10 md:flex flex-row justify-center py-12 px-6 lg:px-8 container mx-auto">
      <div className="md:w-1/2 md:mr-8">
        <div>
          <h1 className="text-3xl font-bold text-gray-900 border-b border-solid pb-4 border-gray-300">
            Recuperar senha
          </h1>
        </div>

        <div className="mt-8 ">
          <div>
            <form
              name="signin-form"
              className="mb-0 space-y-6"
              onSubmit={handleSubmit(handleRecoveryPassword)}
            >
              <div>
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-gray-700"
                >
                  Código
                </label>
                <div className="mt-1">
                  <input
                    className="w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700"
                    id="code"
                    {...register('code')}
                    placeholder="Código"
                    type="text"
                    required
                  />
                  {errors.code && (
                    <span className="text-red-500">{errors.code.message}</span>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  Usuário
                </label>
                <div className="mt-1">
                  <input
                    className="w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700"
                    id="username"
                    {...register('username')}
                    placeholder="CPF"
                    type="text"
                    required
                  />
                  {errors.username && (
                    <span className="text-red-500">
                      {errors.username.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Senha
                </label>
                <div className="mt-1">
                  <input
                    className="w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700"
                    id="password"
                    {...register('password')}
                    placeholder="Senha"
                    type="password"
                    autoComplete="current-password"
                  />
                  {errors.password && (
                    <span className="text-red-500">
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div>
                  <Button
                    disabled={isSubmitting || !isValid}
                    loading={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting ? 'Enviando...' : 'Enviar'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
