import Keycloak from 'keycloak-js';

const url = process.env.REACT_APP_KEYCLOAK_URL!;
const realm = process.env.REACT_APP_KEYCLOAK_REALM!;
const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID!;

const keycloakConfig = {
  url,
  realm,
  clientId,
};

export const keycloak = new Keycloak(keycloakConfig);
