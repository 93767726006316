import { Route, Routes } from 'react-router-dom';

import { RequireAuth } from './features/auth/RequireAuth';
import { SignIn } from './features/auth/SignIn';
import { Exam } from './features/exam/Exam';
import { Faq } from './features/faq';
import { MyCertifications } from './features/my-certifications/MyCertifications';
import { useGetGlobalQuery } from './features/my-certifications/myCertificationsApiSlice';
import { Profile } from './features/profile/Profile';
import { RecoveryPassword } from './features/recovery-password/RecoveryPassword';
import { Result } from './features/result/Result';
import { SignUp } from './features/sign-up/SignUp';
import { SsoCallback } from './features/sso-callback/SsoCallback';

export function Router() {
  const { data: globalData } = useGetGlobalQuery(
    { apiKey: undefined },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    },
  );

  if (!globalData) {
    return null;
  }

  return (
    <Routes>
      <Route path="login" element={<SignIn />} />
      <Route path="/" element={<SignIn />} />
      <Route path="*" element={<SignIn />} />

      {globalData?.loginEnable && <Route path="sign-up" element={<SignUp />} />}
      {globalData?.loginEnable && (
        <Route path="sso-callback" element={<SsoCallback />} />
      )}
      {globalData?.loginEnable && (
        <Route path="sign-up/:id" element={<SignUp />} />
      )}

      <Route path="faq" element={<Faq />} />
      <Route path="recovery-password" element={<RecoveryPassword />} />

      <Route element={<RequireAuth />}>
        {!globalData?.loginEnable && (
          <Route path="sign-up" element={<SignUp />} />
        )}
        {!globalData?.loginEnable && (
          <Route path="sign-up/:id" element={<SignUp />} />
        )}
        <Route path="my-certifications" element={<MyCertifications />} />
        <Route path="exam/:certificationId" element={<Exam />} />
        <Route path="result/:id" element={<Result />} />
        <Route path="profile" element={<Profile />} />
      </Route>
    </Routes>
  );
}
