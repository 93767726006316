import platform from 'platform';
import { useEffect, useState } from 'react';
import Hotkeys from 'react-hot-keys';
import { toast } from 'react-toastify';
import useWindowFocus from 'use-window-focus';

import { useSendInfringementMutation } from '../../features/exam/certificationApiSlice';
import usePrintScreenDetection from '../../hooks/usePrintScreenDetection';

type PropsInfringement = {
  certification_log: string;
  onFinalize: () => void;
};

export function Infringement({
  certification_log,
  onFinalize,
}: PropsInfringement) {
  const [total, setTotal] = useState(0);
  const windowFocused = useWindowFocus();

  const [sendInfringement] = useSendInfringementMutation();

  async function handleInfringement(rule: string) {
    setTotal(total + 1);

    const payload = {
      certification_log,
      browser: { version: platform.description || '' },
      rule,
    };

    await sendInfringement(payload);

    toast.warn(`Você infringiu a regra: ${rule}`, {
      position: 'top-center',
      autoClose: 15000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  const handlePrintScreenDetected = async () => {
    handleInfringement('tirou print screen da tela');
  };

  usePrintScreenDetection(handlePrintScreenDetected);

  useEffect(() => {
    if (!windowFocused) {
      handleInfringement('perdeu o foco do navegador');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowFocused]);

  if (total === 4) {
    onFinalize();
  }

  return (
    <Hotkeys
      // keyName="esc,alt,ctrl,tab,shift,prtsc"
      keyName="command+shift+3,command+shift+4,command+shift+4+space,prtsc"
      onKeyDown={keyName => handleInfringement(`pressionou ${keyName}`)}
    />
  );
}
