import { apiSlice } from '../../app/api/apiSlice';
import { CepAddress } from '../../types/auth/cep';
import { User } from '../../types/auth/User';
import { UploadResult } from '../../types/certifications/Certification';
import { FormUser } from './Profile';

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getUser: query<User, null>({
      query: () => ({
        url: `player/me`,
      }),
    }),

    getCep: mutation<CepAddress, string>({
      query: cep => ({
        url: `https://viacep.com.br/ws/${cep.replaceAll('-', '')}/json/`,
      }),
    }),

    sendMedia: mutation<UploadResult, FormData>({
      query: payload => ({
        url: `upload/file`,
        method: 'POST',
        body: payload,
      }),
    }),

    updateUser: mutation<User, FormUser>({
      query: payload => ({
        url: `player/me`,
        method: 'PUT',
        body: payload,
      }),
    }),

    updatePassword: mutation<
      { _id: string },
      { _id: string; password: string }
    >({
      query: payload => ({
        url: `database/change_password__c`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useSendMediaMutation,
  useUpdateUserMutation,
  useGetCepMutation,
  useUpdatePasswordMutation,
} = profileApiSlice;
