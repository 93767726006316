import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { apiSlice } from '../../app/api/apiSlice';
import {
  Correspondente,
  UserClient,
  UserClientPreEnroll,
} from '../../types/auth/User';
import { UploadResult } from '../../types/certifications/Certification';
import { getRegistrationToken } from '../../utils/getRegistrationToken';
import { sanitizeCpf } from '../../utils/sanitize-cpf';
import { CreateUser } from './SignUp';

export const signUpApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    createUser: mutation<
      { code: string; status: string },
      { user: CreateUser; apiKey?: string }
    >({
      query: payload => ({
        url: `database/registration__c`,
        method: 'PUT',
        headers: {
          Authorization: getRegistrationToken(payload.apiKey),
        },
        body: payload.user,
      }),
    }),

    sendMediaRegister: mutation<
      UploadResult,
      { data: FormData; apiKey?: string }
    >({
      query: payload => ({
        url: `upload/file`,
        method: 'POST',
        headers: {
          Authorization: getRegistrationToken(payload.apiKey),
        },
        body: payload.data,
      }),
    }),

    loadUser: query<
      UserClient,
      { _id: string; renew: boolean; isPreEnroll: boolean }
    >({
      // @ts-expect-error
      async queryFn(
        { _id, renew, isPreEnroll },
        _api,
        _extraOptions,
        baseQuery,
      ) {
        let userData: UserClient | undefined;

        if (isPreEnroll && !renew) {
          const userPreEnroll = await baseQuery({
            url: 'database/find_pre_enroll__c?strict=true',
            method: 'PUT',
            headers: {
              Authorization: process.env.REACT_APP_FUNIFIER_FIND_PROVIDER_TOKEN,
            },
            body: { _id },
          });

          if (userPreEnroll.error) {
            return { error: userPreEnroll.error };
          }

          const rawData = userPreEnroll.data as UserClientPreEnroll;

          const parsedData: UserClient = {
            _id: rawData.data[0]._id,
            cpf:
              rawData.data[0].cpf.length < 11
                ? rawData.data[0].cpf.padStart(11, '0')
                : rawData.data[0].cpf,
            name: rawData.data[0].name,
            email: rawData.data[0].email,
            apiKey: rawData.data[0].apikey,
            cnpj: rawData.data[0].cnpjCorrespondent,
            ...(rawData.data[0].phone && { phone: rawData.data[0].phone }),
            userAlreadyExists: false,
            correspondente: {
              name: rawData.data[0].nameCorrespondent,
              cnpj: rawData.data[0].cnpjCorrespondent,
            },
          };

          userData = parsedData;
        }

        if (!renew && !isPreEnroll) {
          const userPracta = await baseQuery({
            url: 'database/practa_user__c/aggregate?strict=true',
            method: 'POST',
            headers: {
              Authorization: getRegistrationToken(),
            },
            body: [
              {
                $match: { _id },
              },
            ],
          });

          if (userPracta.error) {
            return { error: userPracta.error };
          }

          [userData] = userPracta.data as UserClient[];
        }

        if (renew && !isPreEnroll) {
          const userBradescoLegacy = await baseQuery({
            url: 'database/bradesco_legacy__c/aggregate?strict=true',
            method: 'POST',
            headers: {
              Authorization: getRegistrationToken(),
            },
            body: [
              {
                $match: { _id },
              },
            ],
          });

          if (userBradescoLegacy.error) {
            return { error: userBradescoLegacy.error };
          }

          [userData] = userBradescoLegacy.data as UserClient[];
        }

        if (!userData) {
          return { error: 'Usuário não encontrado' } as FetchBaseQueryError;
        }

        let correspondentFound: Correspondente[] = [];

        if (userData.correspondente) {
          const result = await baseQuery({
            url: 'database/correspondente__c/aggregate?strict=true',
            method: 'POST',
            headers: {
              Authorization: getRegistrationToken(),
            },
            body: [
              {
                $match: {
                  cnpj: userData.cnpj,
                },
              },
            ],
          });

          correspondentFound = result.data as Correspondente[];
        }

        const resultPlayer = await baseQuery({
          url: `player/${sanitizeCpf(userData.cpf)}`,
          method: 'GET',
          headers: {
            Authorization: getRegistrationToken(),
          },
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const hasUserRegister = resultPlayer.data as any;

        if (correspondentFound.length > 0 && hasUserRegister.length > 0) {
          userData = {
            ...userData,
            userAlreadyExists: true,
          };
        }

        if (correspondentFound.length > 0) {
          userData = {
            ...userData,
            correspondente: correspondentFound[0],
          };
        }

        return correspondentFound || userData.correspondente?.name
          ? {
              data: {
                ...userData,
              } as UserClient,
            }
          : ({ error: 'Usuário não encontrado' } as FetchBaseQueryError);
      },
    }),
  }),
});

export const {
  useCreateUserMutation,
  useSendMediaRegisterMutation,
  useLoadUserQuery,
} = signUpApiSlice;
