/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, useEffect } from 'react';

type VideoPreviewProps = {
  stream: MediaStream;
  height?: number;
  width?: number;
  rounded?: boolean;
};

export function VideoPreview({
  stream,
  height = 100,
  width = 100,
  rounded = false,
}: VideoPreviewProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }

  return (
    <div
      className={`${rounded ? 'rounded-full overflow-hidden' : ''}`}
      style={{
        height: rounded ? 'auto' : `${height}px`,
        width: rounded ? `${width - 20}px` : `${width}px`,
      }}
    >
      <video
        className={`${rounded ? '' : 'rounded-2xl'}`}
        ref={videoRef}
        width={width}
        height={height}
        autoPlay
        playsInline
      />
    </div>
  );
}
